<template>

  <b-card

    title="Add new shipment"
  >
    <b-form @submit.prevent>
      <b-row>

        <b-col cols="6">
          <b-form-group
            label="SKU"
            label-for="v-sku"
          >
            <b-form-input
              id="v-sku"
              v-model="shipment.sku"
              placeholder="SKU"
              required
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Channel"
            label-for="v-channel"
          >
            <b-form-select
              v-model="shipment.channel"
              :options="channelOptions"
              required
            />

          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Value"
            label-for="v-value"
          >
            <b-form-input
              id="v-value"
              v-model="shipment.value"
              placeholder="Value"
              required
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Status"
            label-for="v-status"
            required
          >
            <b-form-select
              v-model="shipment.status"
              :options="statusOptions"
            />

          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
            @click="saveShipment"
          >
            Add
          </b-button>

          <router-link :to="{name:'shipments'}">

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >

              Back
            </b-button>

          </router-link>

        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      shipment: {},
      loading: false,
      channelSelected: null,
      channelOptions: [
        { value: 'Amazon', text: 'Amazon' },
        { value: 'Website', text: 'Website' },

      ],
      statusOptions: [
        { value: 'intransit', text: 'In transit' },
        { value: 'prodcution', text: 'Prodcution' },

      ],
    }
  },
  methods: {
    saveShipment() {
      this.loading = false

      this.$store.dispatch('shipment/create', this.shipment)
        .then(result => {
          this.showToast('Success', 'Item added with success', 'success')

          this.$router.push({ name: 'shipments' })
        },
        () => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while saving', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
